import { css } from "@emotion/react"
import React from "react"

const Footer = () => {
  return (
    <footer
      css={css`
        height: 140px;
        justify-content: center;
        background-color: #222;
        font-size: 12px;
        color: #666;
        display: flex;
        align-items: center;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;

          a {
            color: #496ee8;
            font-size: 14px;
            margin: -1rem 1.2rem 1rem;
          }
        `}
      >
        <a href="http://www.kidslandchina.com/nd.jsp?id=90" target="_blank">
          法律聲明
        </a>
        <a href="http://www.kidslandchina.com/col.jsp?id=127" target="_blank">
          聯繫我們
        </a>
        <a>加入我們</a>
      </div>
      <span
        css={css`
          margin: 0.2rem;
        `}
      >
        ©2019 kidsland凯知乐 版权所有
      </span>
      <span
        css={css`
          margin: 0.2rem;
        `}
      >
        京ICP备18020377号
      </span>
    </footer>
  )
}

export default Footer
