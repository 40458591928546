/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { css, Global } from "@emotion/react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { FC, Fragment } from "react"
import Footer from "./Footer"
import Header from "./Header"

type Props = {
  fluid?: boolean
  langKey: string
}

const Layout: FC<Props> = ({ children, fluid = false, langKey }) => {
  const { heroBanner } = useStaticQuery(graphql`
    query {
      heroBanner: sanitySiteSettings {
        banner {
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 1920
              height: 403
            )
          }
        }
      }
      # hero_banner: file(relativePath: { eq: "hero_banner.jpg" }) {
      #   childImageSharp {
      #     fluid(quality: 100, maxWidth: 1200) {
      #       ...GatsbyImageSharpFluid_withWebp
      #     }
      #   }
      # }
    }
  `)

  const image = getImage(heroBanner.banner.asset)

  // Use like this:
  const bgImage = convertToBgImage(image)

  return (
    <Fragment>
      <Global
        styles={css`
          html,
          body {
            margin: 0;
            padding: 0;
          }

          body {
            height: 100%;
            width: 100%;
            overflow: auto;
            font-family: 微软雅黑;
          }

          main {
            padding-top: 120px;
          }

          a {
            text-decoration: none;
          }

          * {
            box-sizing: border-box;
          }
        `}
      />
      <Header internal langKey={langKey} />

      <main>
        {/* <img
          src={hero_banner.banner.asset.fixed.src}
          css={css`
            width: 100%;
            height: 380px;
            cursor: pointer;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            overflow: hidden;
            display: block;
            outline: none;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            background-size: 100% auto;
          `}
        /> */}

        <BackgroundImage
          Tag="section"
          // Spread bgImage into BackgroundImage:
          {...bgImage}
          // preserveStackingContext
        >
          {/* <div style={{ minHeight: 1000, minWidth: 1000 }}> */}
          <GatsbyImage image={image} alt={"Kidsland"} />
          {/* </div> */}
        </BackgroundImage>
        {/* <BackgroundImage
          Tag="section"
          {...bgImage}
          backgroundColor={`#fff`}
          css={css`
            width: 100%;
            height: 403px;
            cursor: pointer;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            overflow: hidden;
            display: block;
            outline: none;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            background-size: 100% auto;
          `}
        /> */}

        <div
          css={css`
            min-height: 60vh;
            width: ${fluid ? "1200px" : "834px"};
            max-width: 95%;
            border-spacing: 0;
            overflow: hidden;
            padding: 0 0 10px 0;
            margin: 0 auto;
            height: auto;
          `}
        >
          {children}
        </div>
      </main>
      <Footer />
    </Fragment>
  )
}

export default Layout
